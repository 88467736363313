


import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import questionnairesApi from '@/_api/questionnaires.api';
import _cloneDeep from 'lodash.clonedeep';
import {
  QuestionTypes,
  TQuestionnaire,
  TQuestionnaireQuestion,
  TQuestionnaireQuestionAnswerOption
} from '@/_types/questionnaire.type';
import IconSquareDelete from '@/_modules/icons/components/icon-square-delete.vue';
import SimplePopup from '@/_modules/controls/components/simple-popup/simple-popup.vue';

const RATING_OPTIONS_FROM = 1;
const RATING_OPTIONS_TO = 10;

@Component({
  components: {
    IconSquareDelete,
    SimplePopup,
  }
})
export default class EditQuestionnaire extends Vue {

  public isSavingInProgress: boolean = false;

  public poll: TQuestionnaire = {
    id: -1,
    title: '',
    questions: []
  };

  public emptyQuestionStruct: TQuestionnaireQuestion = {
    id: -1,
    text: '',
    question_type: QuestionTypes.NONE,
    answer_options: [],
    is_enabled: true,
  }

  public emptyAnswerOptionStruct: TQuestionnaireQuestionAnswerOption = {
    id: -1,
    question_id: null,
    text: ''
  }

  public isTypeConfirmationVisible: boolean = false;
  public tempQuestionIndex: number = null;

  public questionTypeNames: typeof QuestionTypes = QuestionTypes;
  public newQuestionTypeName: QuestionTypes = QuestionTypes.NONE;

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get programId(): number {
    return (this.$route.params.programId && parseInt(this.$route.params.programId, 10)) || null;
  }

  @Prop({type: Object, default: null})
  public readonly editingPoll: TQuestionnaire;

  @Watch('editingPoll', {immediate: true})
  public onEditingPollChange(): void {
    this.initForm();
  }

  public get isEditMode(): boolean {
    return !!this.editingPoll;
  }

  public get isSavePollButtonDisabled(): boolean {
    return !this.poll || !this.isPollDataValid() || this.isSavingInProgress;
  }

  public get formTitle(): string {
    if (this.isEditMode) {
      return this.$t('polls.popupTitleEditMode') as string;
    }

    return this.$t('polls.popupTitle') as string;
  }

  public initForm(): void {
    if (!this.editingPoll) {
      return;
    }

    this.poll = this.getPollWithPreprocessedFreeTextQuestions();

  }

  private getPollWithPreprocessedFreeTextQuestions(): TQuestionnaire {
    // For AW-2366, add empty arrays into free-text questions for Vue reactivity
    const result = _cloneDeep(this.editingPoll);
    result.questions
      .filter(q => q.question_type === QuestionTypes.TEXT)
      .forEach(q => {
        if (!q.answer_options) {
          q.answer_options = [];
        }
      });
    return result;
  }

  private async onSubmitPoll(): Promise<void> {

    if (this.isSavePollButtonDisabled) {
      return;
    }

    if (!this.isPollDataValid()) {
      return; // TODO: display errors
    }

    this.isSavingInProgress = true;

    if (this.isEditMode) {
      await this.editPoll();
    } else {
      const savePollResult: TQuestionnaire = await this.savePoll();
      if (savePollResult && savePollResult.id) {
        this.poll.id = savePollResult.id;
      }
    }

    await this.saveQuestions();

    await this.saveAnswerOptions();

    this.isSavingInProgress = false;

    this.emitSuccess();

  }

  private isPollDataValid(): boolean {

    // Poll title is necessary
    if (!this.poll.title.trim()) {
      return false;
    }

    for (let i = 0; i < this.poll.questions.length; i++) {
      const question = this.poll.questions[i];

      // Disallow questions with no question_type selected
      if (!question.question_type) {
        return false;
      }

      // Disallow empty question titles
      if (!question.text.trim()) {
        return false;
      }

      // Disallow OPTION or MULTI questions without any options
      if (
        (!question.answer_options || question.answer_options.length === 0)
        && (question.question_type === QuestionTypes.MULTI || question.question_type === QuestionTypes.OPTION)
      ) {
        return false;
      }

      // Disallow empty answer option texts
      if (question.answer_options) {
        for (let a = 0; a < question.answer_options.length; a++) {
          if (question.answer_options[a].text.trim() === '') {
            return false;
          }
        }
      }
    }

    return true;
  }

  private async editPoll(): Promise<boolean> {
    return await questionnairesApi.editQuestionnaire({
      eventId: this.eventId,
      questionnaireId: this.poll.id,
      title: this.poll.title.trim()
    });
  }

  private async savePoll(): Promise<TQuestionnaire> {
    return await questionnairesApi.createQuestionnaire({
      eventId: this.eventId,
      programId: this.programId,
      title: this.poll.title.trim()
    });
  }

  private async saveQuestions(): Promise<void> {

    if (!this.isEntityInDatabase(this.poll)) {
      return;
    }

    for (const question of this.poll.questions) {
      if (this.isEntityInDatabase(question)) {
        await questionnairesApi.editQuestion({
          eventId: this.eventId,
          questionnaireId: this.poll.id,
          questionId: question.id,
          text: question.text,
          questionType: question.question_type,
          isEnabled: true,
        });
      } else {
        const savedQuestion = await questionnairesApi.createQuestion({
          eventId: this.eventId,
          questionnaireId: this.poll.id,
          text: question.text,
          questionType: question.question_type,
          isEnabled: true,
        });
        if (savedQuestion && savedQuestion.id) {
          question.id = savedQuestion.id;
        }
      }
    }
  }

  private async saveAnswerOptions(): Promise<void> {
    for (const question of this.poll.questions) {
      if (!this.isSavingAnswerOptionsNeeded(question)) {
        continue;
      }

      if (question.question_type === QuestionTypes.RATING) {
        this.addRatingOptions(question);
      }

      for (const answerOption of question.answer_options) {
        if (!this.isEntityInDatabase(answerOption)) {
          const savedOption = await questionnairesApi.createAnswerOption({
            eventId: this.eventId,
            questionnaireId: this.poll.id,
            questionId: question.id,
            text: answerOption.text
          });
          if (savedOption && savedOption.id) {
            answerOption.id = savedOption.id;
            answerOption.question_id = question.id;
          }
        }
      }
    }
  }

  private isSavingAnswerOptionsNeeded(question: TQuestionnaireQuestion): boolean {
    const questionTypesWithOptions: QuestionTypes[] = [
      QuestionTypes.OPTION,
      QuestionTypes.MULTI,
      QuestionTypes.RATING
    ];
    return questionTypesWithOptions.indexOf(question.question_type) >= 0;
  }

  private addRatingOptions(question: TQuestionnaireQuestion): void {
    if (!this.isRatingOptionsListValid(question)) {
      if (question.answer_options && question.answer_options.length) {
        this.sanitizeRatingOptions(question);
      }
      const result: TQuestionnaireQuestionAnswerOption[] = [];
      for (let i = RATING_OPTIONS_FROM; i <= RATING_OPTIONS_TO; i++) {
        const newOption: TQuestionnaireQuestionAnswerOption = Object.assign({}, this.emptyAnswerOptionStruct);
        newOption.question_id = question.id;
        newOption.text = i.toFixed(0);
        newOption.id = 0 - (result.length + 1);
        result.push(newOption);
      }
      question.answer_options = result;
    }
  }

  private sanitizeRatingOptions(question: TQuestionnaireQuestion): void {
    const questionIndex = this.poll.questions.findIndex((q: TQuestionnaireQuestion) => q.id === question.id);
    let pos = question.answer_options.length;
    while (pos--) {
      this.deleteAnswerOption(questionIndex, pos);
    }
  }

  private isRatingOptionsListValid(question: TQuestionnaireQuestion): boolean {
    if (!question.answer_options || question.answer_options.length === 0) {
      return false;
    }

    const serializedOptions: string = question.answer_options.map(x => x.text).join(',');
    const serializedValidOptions: string = ((): string => {
      const result: number[] = [];
      for (let i = RATING_OPTIONS_FROM; i <= RATING_OPTIONS_TO; i++) {
        result.push(i);
      }
      return result.join(',');
    })();

    return serializedOptions === serializedValidOptions;
  }

  private emitSuccess(): void {
    this.$emit('success', this.poll);
  }

  private onAddQuestionClick(): void {
    this.addQuestion();
    this.$nextTick(this.scrollQuestionListToBottom);
  }

  private scrollQuestionListToBottom(): void {
    try {
      const questionList: Element = this.$refs.questionList as Element;
      questionList.scrollTop = 1000000;
    } catch {
      /* ignore */
    }
  }

  private addQuestion(): void {
    const newQuestionDummy: TQuestionnaireQuestion = _cloneDeep(this.emptyQuestionStruct);
    newQuestionDummy.id = 0 - (1 + this.poll.questions.length);
    this.poll.questions.push(newQuestionDummy);
  }

  private addAnAnswer(questionIndex: number): void {
    const question: TQuestionnaireQuestion = this.poll.questions[questionIndex];
    const newAnswerOptionDummy: TQuestionnaireQuestionAnswerOption = _cloneDeep(this.emptyAnswerOptionStruct);
    if (!question.answer_options) {
      question.answer_options = [];
    }
    newAnswerOptionDummy.id = 0 - (1 + this.poll.questions[questionIndex].answer_options.length);
    newAnswerOptionDummy.question_id = question.id;
    question.answer_options.push(newAnswerOptionDummy);
    this.poll = Object.assign({}, this.poll);
  }

  private toggleQuestionFolded(questionIndex: number): void {
    const questionBox: Element = (this.$refs.pollQuestionEditor as Element[])[questionIndex];
    const className = 'question-folded';
    if (questionBox.classList.contains(className)) {
      questionBox.classList.remove(className);
    } else {
      questionBox.classList.add(className);
    }
  }

  private onQuestionTypeClick(question: TQuestionnaireQuestion, questionIndex: number, targetQuestionTypeName: QuestionTypes): void {

    if (!question.answer_options || !question.answer_options.length) {
      question.question_type = targetQuestionTypeName;
      this.unfoldQuestion(questionIndex);
      return;
    }

    this.newQuestionTypeName = targetQuestionTypeName;
    this.tempQuestionIndex = questionIndex;

    this.isTypeConfirmationVisible = true;

  }

  private changeTypeConfirmation(isConfirmed: boolean): void {

    const questionBeingEdited: TQuestionnaireQuestion = this.poll.questions[this.tempQuestionIndex];

    this.isTypeConfirmationVisible = false;

    if (isConfirmed) {
      if (
        questionBeingEdited
        && (questionBeingEdited.question_type !== this.newQuestionTypeName)
      ) {

        if (questionBeingEdited.answer_options) {
          let pos = questionBeingEdited.answer_options.length;
          while (pos--) {
            this.deleteAnswerOption(this.tempQuestionIndex, pos);
          }

          questionBeingEdited.answer_options = [];
        }

        this.unfoldQuestion(this.tempQuestionIndex);
        questionBeingEdited.question_type = this.newQuestionTypeName;

      }
    }

    this.newQuestionTypeName = QuestionTypes.NONE;
  }

  private unfoldQuestion(questionIndex: number): void {
    const questionBox: Element = (this.$refs.pollQuestionEditor as Element[])[questionIndex];
    const className = 'question-folded';
    questionBox.classList.remove(className);
  }

  private deleteQuestion(questionIndex: number): void {
    const question = this.poll.questions[questionIndex];
    if (!question) {
      return;
    }
    if (this.isEntityInDatabase(question)) {
      questionnairesApi.deleteQuestion({
        eventId: this.eventId,
        questionnaireId: this.poll.id,
        questionId: question.id
      });
    }

    this.poll.questions.splice(questionIndex, 1);
  }

  private deleteAnswerOption(questionIndex: number, answerOptionIndex: number): void {
    const question: TQuestionnaireQuestion = this.poll.questions[questionIndex];
    const answerOption: TQuestionnaireQuestionAnswerOption = question.answer_options[answerOptionIndex];

    if (this.isEntityInDatabase(answerOption)) {
      questionnairesApi.deleteAnswerOption({
        eventId: this.eventId,
        questionnaireId: this.poll.id,
        questionId: question.id,
        answerOptionId: answerOption.id
      });
    }

    question.answer_options.splice(answerOptionIndex, 1);
  }

  private isEntityInDatabase(entity: TQuestionnaire | TQuestionnaireQuestion | TQuestionnaireQuestionAnswerOption): boolean {
    return entity && entity.id && entity.id > 0;
  }
}
