


import Component from 'vue-class-component';
import {Location} from 'vue-router';
import {Vue, Watch} from 'vue-property-decorator';
import {Validations} from 'vuelidate-property-decorators';
import {Moment} from 'moment';
import {maxLength, required} from 'vuelidate/lib/validators';
import {fromEvent, Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import IconArrowLeft from '@/_modules/icons/components/icon-arrow-left.vue';
import FormInputText from '@/_modules/controls/components/form-input-text/form-input-text.vue';
import {mapGetters} from 'vuex';
import {TContact} from '@/_types/contact.type';
import IconSearch from '@/_modules/icons/components/icon-search.vue';
import Avatar from '@/_components/avatar/avatar.vue';
import {TVuelidateRuleSet} from '@/_types/vuelitation-rule-set.type';
import FormFileUploader from '@/_modules/controls/components/form-file-uploader/form-file-uploader.vue';
import {TFile} from '@/_types/file.type';
import {TConferenceProgram} from '@/_modules/promo/types/conference-program.type';
import {DateTimeFormat} from '@/_types/date-time-format.enum';
import promoProgramApi, {
  TCreateConferenceProgramParams,
  TPatchConferenceProgramParams
} from '@/_modules/promo-program/api/promo-program.api';
import {TMeetingRoomConfig} from '@/_modules/meeting-rooms/types/meeting-room-config.type';
import {BroadcastType} from '@/_types/broadcasts/broadcast-type.enum';
import {MeetingRoomType} from '@/_modules/meeting-rooms/types/meeting-room-type.enum';
import {TEvent} from '@/_types/event.type';
import EventHelper from '@/_helpers/event.helper';
import {TimeStatus} from '@/_types/time-status.enum';
import {TApiListResponse} from '@/_types/api/api-list-response.type';
import SimplePopup from '@/_modules/controls/components/simple-popup/simple-popup.vue';
import CabinetCreateUser from '@/_modules/promo-cabinet/components/cabinet-create-user/cabinet-create-user.vue';
import EditQuestionnaire from '@/_modules/promo-cabinet/components/edit-questionnaire/edit-questionnaire.vue';
import questionnairesApi from '@/_api/questionnaires.api';

import IconSquareEdit from '@/_modules/icons/components/icon-square-edit.vue';
import IconSquareDelete from '@/_modules/icons/components/icon-square-delete.vue';
import IconPlus from '@/_modules/icons/components/icon-plus.vue';
import ValidationHelper from '@/_helpers/validation.helper';
import {TSponsor} from '@/_types/promo-page/live-page.type';
import {TQuestionnaire} from '@/_types/questionnaire.type';
import momentTimezone from 'moment-timezone';

const FILE_ICON = require('@/assets/images/icons/blank-file.svg');

const CONTACT_SEARCH_LIMIT = 5;
const CONTACT_SEARCH_DEBOUNCE_TIME = 1000;

type TConferenceProgramFormData = {
  date: Date;
  timeStart: Date;
  timeEnd: Date;
  title: string;
  speakers: number[];
  isLiveChatOn: boolean;
  isSpeakerChatOn: boolean;
  posterFile: TFile;
  description: string;
  videoStreamEmbed: string;
  vodFile: TFile;
  files: TFile[];
}

@Component({
  components: {
    IconArrowLeft,
    FormInputText,
    IconSearch,
    Avatar,
    FormFileUploader,
    SimplePopup,
    CabinetCreateUser,
    EditQuestionnaire,
    IconSquareEdit,
    IconSquareDelete,
    IconPlus,
  },
  computed: {
    ...mapGetters({
      contact: 'promoPageStore/contact',
      lastError: 'promoProgramStore/lastError',
      getProgramById: 'promoProgramStore/getProgramById',
      event: '_eventStore/event',
      contactById: 'contactsStore/contactById',
    }),
  },
})
export default class CabinetProgramForm extends Vue {

  public readonly CONTACT_SEARCH_LIMIT: typeof CONTACT_SEARCH_LIMIT = CONTACT_SEARCH_LIMIT;
  public readonly isEventContactsLoading: boolean;
  public readonly contact: TContact;
  public readonly lastError: Error;
  public readonly getProgramById: (programId: number) => TConferenceProgram;
  public readonly event: TEvent;
  public readonly contactById: (contactId: number) => TContact;
  public isAddUserPopupVisible: boolean = false;
  public isPosterFileLoading: boolean = false;
  public posterFileError: string = '';

  public filesError: string = '';
  public files: TFile[] = [];

  public sponsorFile: TSponsor = {};
  public sponsorLink: string = '';
  public sponsorFileError: string = '';
  public isSponsorFileLoading: boolean = false;

  public isFilesLoading: boolean = false;
  public vodError: string = '';
  public isVodLoading: boolean = false;

  public isQuestionnairePopupVisible: boolean = false;
  public editingPoll: TQuestionnaire = null;
  public pollToDeleteId: number = null;

  @Validations()
  public readonly validations: TVuelidateRuleSet<TConferenceProgramFormData & TSponsor> = {
    formData: {
      date: {
        required,
      },
      timeStart: {
        required,
        timeOrder: (): boolean => {
          return this.$moment(this.formData.timeStart).isBefore(this.$moment(this.formData.timeEnd));
        },
      },
      timeEnd: {
        required,
      },
      title: {
        required,
      },
      speakers: {},
      isLiveChatOn: {},
      isSpeakerChatOn: {},
      posterFile: {},
      description: {},
      videoStreamEmbed: {},
      vodFile: {},
      files: {},
    },
    sponsorFile: {
      link: {
        link: ValidationHelper.isValidUrl,
        maxLength: maxLength(250),
      }
    }
  }

  public get mode(): 'create' | 'edit' {
    return (
      this.$route.name === 'promo-page-cabinet-program-create'
      || this.$route.name === 'promo-page-cabinet-program-date-create'
    ) ? 'create' : 'edit';
  }

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get date(): string {
    return this.$route.params.date || null;
  }

  public get programId(): number {
    return (this.$route.params.programId && parseInt(this.$route.params.programId, 10)) || null;
  }

  public get backLocation(): Location {
    const date = (this.dateMoment && this.dateMoment.format(DateTimeFormat.DATE_TINY)) || this.date;
    if (date) {
      return {
        name: 'promo-page-cabinet-program-date',
        params: {
          date
        }
      };
    } else {
      return {
        name: 'promo-page-cabinet-program',
      };
    }
  }

  public get program(): TConferenceProgram {
    if (!this.programId) {
      return null;
    }
    return this.getProgramById(this.programId);
  }

  public get conferenceId(): number {
    if (this.mode === 'create') {
      return (this.$route.params.conferenceId && parseInt(this.$route.params.conferenceId, 10)) || null;
    } else {
      return null;
    }
  }

  public get dateMoment(): Moment {
    if (this.mode === 'create') {
      return this.$route.params.date ? this.$moment(this.$route.params.date) : null;
    } else {
      return null;
    }
  }

  public get selectedSpeakers(): TContact[] {
    const speakers: TContact[] = this.formData.speakers
      .map(speakerId => (this.contactById(speakerId) || null))
      .filter(speaker => !!speaker);

    // Below uniquefication was made for AW-2021
    const speakersById: { [key: number]: TContact } = {};
    speakers.forEach((speaker) => {
      speakersById[speaker.id] = speaker;
    });
    return Object.values(speakersById);
  }

  public get isLoading(): boolean {
    return this.isEventContactsLoading;
  }

  public get meetingRoomConfig(): TMeetingRoomConfig {
    if (!this.eventId || !this.contact || !this.contact.id) {
      return null;
    }

    return {
      type: MeetingRoomType.BROADCAST,
      broadcastType: BroadcastType.PROGRAM_SPEAKER,
      eventId: this.eventId,
      contactId: this.contact.id,
      programId: this.programId,
    };
  }

  public get computedFileSrc(): string {
    return FILE_ICON;
  }

  public get isSubmitButtonDisabled(): boolean {
    return this.isProcessing;
  };

  public formData: TConferenceProgramFormData = {
    date: null,
    timeStart: null,
    timeEnd: null,
    title: '',
    speakers: [],
    isLiveChatOn: true,
    isSpeakerChatOn: true,
    posterFile: null,
    description: '',
    videoStreamEmbed: null,
    vodFile: null,
    files: [],
  };
  public isContactSearchInputFocused: boolean = false;
  public foundContacts: TContact[] = [];
  public isSearchedThroughAll: boolean = true;
  public isSearching: boolean = false;
  public searchString: string = '';
  public isSearchResultsVisible: boolean = false;
  public isProcessing: boolean = false;
  public uploadingDocument: TFile = null;

  public questionnaires: TQuestionnaire[] = [];

  private destroyed$: Subject<void> = new Subject<void>();
  private contactsSearch$: Subject<void> = new Subject<void>();

  public created(): void {
    this.contactsSearch$.pipe(
      takeUntil(this.destroyed$),
      debounceTime(CONTACT_SEARCH_DEBOUNCE_TIME),
    ).subscribe(() => {
      this.updateFoundContacts();
    });

    this.subscribeToPageEvents();
  }

  public activated(): void {

    if (this.mode === 'create') {
      if (!this.conferenceId || !this.dateMoment) {
        this.$router.push(this.backLocation);
        return;
      }
    } else {
      if (!this.programId || !this.program) {
        this.$router.push(this.backLocation);
        return;
      }
    }

    this.initFormData();
    this.contactsSearch$.next();
  }

  public beforeDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public onBroadcastChoiceEmbedClick(): void {
    const meetingRoomConfig = this.meetingRoomConfig;
    if (!meetingRoomConfig) {
      return;
    }
    this.$store.dispatch('_eventStore/setEmbedCodeDialogConfig', meetingRoomConfig);
  }

  public onBroadcastChoiceZoomClick(): void {
    const eventTimeStatus = EventHelper.getEventTimeStatus(this.event);
    if (eventTimeStatus === TimeStatus.PAST) {
      this.$store.dispatch('_eventStore/setIsBroadcastTimeCheckDialogVisible', true);
      return;
    }
    const meetingRoomConfig = this.meetingRoomConfig;
    if (!meetingRoomConfig) {
      return;
    }
    this.$store.dispatch('_eventStore/setZoomSettingsDialogConfig', meetingRoomConfig);
  }

  public onBroadcastChoiceOBSClick(): void {
    const eventTimeStatus = EventHelper.getEventTimeStatus(this.event);
    if (eventTimeStatus === TimeStatus.PAST) {
      this.$store.dispatch('_eventStore/setIsBroadcastTimeCheckDialogVisible', true);
      return;
    }
    const meetingRoomConfig = this.meetingRoomConfig;
    if (!meetingRoomConfig) {
      return;
    }
    this.$store.dispatch('_eventStore/setObsSettingsDialogConfig', meetingRoomConfig);
  }

  public onContactSearchInputFocusIn(): void {
    this.isSearchResultsVisible = true;
  }

  public onFoundContactClick(contact: TContact): void {
    if (this.formData.speakers.indexOf(contact.id) < 0) {
      this.formData.speakers.push(contact.id);
      this.$v.formData.speakers.$touch();
    }
    this.updateFoundContacts();
  }

  public onRemoveSpeakerClick(speaker: TContact): void {
    this.formData.speakers.splice(this.formData.speakers.indexOf(speaker.id), 1);
    this.$v.formData.speakers.$touch();
    this.contactsSearch$.next();
  }

  public onRemoveFileClick(file: TFile): void {
    this.formData.files.splice(this.formData.files.indexOf(file), 1);
    this.$v.formData.files.$touch();
  }

  public onLiveChatSwitchLabelClick(): void {
    this.formData.isLiveChatOn = !this.formData.isLiveChatOn;
    this.$v.formData.isLiveChatOn.$touch();
  }

  public onSpeakerChatSwitchLabelClick(): void {
    this.formData.isSpeakerChatOn = !this.formData.isSpeakerChatOn;
    this.$v.formData.isSpeakerChatOn.$touch();
  }

  public onSearchInputClick(event: MouseEvent): void {
    event.stopPropagation();
    event.stopImmediatePropagation();
  }

  public onDocumentUploaderUploading(file: TFile): void {
    this.uploadingDocument = file;
  }

  public onDocumentUploaderIdle(): void {
    this.uploadingDocument = null;
  }

  public async onSubmitClick(): Promise<void> {
    this.$v.formData.$touch();
    if (this.$v.formData.$invalid) {
      return;
    }

    if (this.isProcessing) {
      return;
    }

    this.isProcessing = true;

    // TODO: split code in methods!

    if (this.mode === 'create') {
      const createConferenceProgramParams: TCreateConferenceProgramParams = this.formData2CreateConferenceProgramParams();
      let newConferenceProgram: TConferenceProgram;
      try {
        newConferenceProgram = await promoProgramApi.createConferenceProgram(createConferenceProgramParams);
        await this.postSponsorFile(newConferenceProgram);
      } catch (error) {
        // TODO: ?
        this.isProcessing = false;
        return;
      }

      if (!newConferenceProgram) {
        // TODO: ?
        this.isProcessing = false;
        return;
      }

      try {
        const promises: Promise<void>[] = [];
        const addedSpeakerIds: number[] = [];
        for (let i = 0; i < this.formData.speakers.length; i++) {
          if (addedSpeakerIds.indexOf(this.formData.speakers[i]) >= 0) {
            continue;
          }
          addedSpeakerIds.push(this.formData.speakers[i]);
          promises.push(
            promoProgramApi.addConferenceProgramSpeaker({
              event_id: this.eventId,
              conference_id: this.conferenceId,
              program_id: newConferenceProgram.id,
              contact_id: this.formData.speakers[i],
            })
          );
        }
        for (let i = 0; i < this.formData.files.length; i++) {
          promises.push(
            promoProgramApi.addConferenceProgramFile({
              event_id: this.eventId,
              conference_id: this.conferenceId,
              program_id: newConferenceProgram.id,
              url: this.formData.files[i].url,
              filename: this.formData.files[i].filename,
            })
          );
        }
        await Promise.all(promises);
      } catch (error) {
        // TODO: ?
        this.isProcessing = false;
        return;
      }

    } else if (this.mode === 'edit') {
      const program = this.program;
      const patchConferenceProgramParams: TPatchConferenceProgramParams = this.formData2PathcConferenceProgramParams();
      try {
        await promoProgramApi.patchConferenceProgram(patchConferenceProgramParams);
      } catch (error) {
        // TODO: ?
        this.isProcessing = false;
        return;
      }

      try {
        const promises: Promise<void>[] = [];

        const oldSpeakersIds: number[] = (program.speakers || []).map(speaker => speaker.id);
        const addedSpeakerIds: number[] = [];
        for (let i = 0; i < this.formData.speakers.length; i++) {
          if (oldSpeakersIds.indexOf(this.formData.speakers[i]) < 0) {
            if (addedSpeakerIds.indexOf(this.formData.speakers[i]) >= 0) {
              continue;
            }
            addedSpeakerIds.push(this.formData.speakers[i]);
            promises.push(
              promoProgramApi.addConferenceProgramSpeaker({
                event_id: this.eventId,
                conference_id: program.conference_id,
                program_id: program.id,
                contact_id: this.formData.speakers[i],
              })
            );
          }
        }
        for (let i = 0; i < oldSpeakersIds.length; i++) {
          if (this.formData.speakers.indexOf(oldSpeakersIds[i]) < 0) {
            promises.push(
              promoProgramApi.deleteConferenceProgramSpeaker({
                event_id: this.eventId,
                conference_id: program.conference_id,
                program_id: program.id,
                contact_id: oldSpeakersIds[i],
              })
            );
          }
        }

        const oldFilesIds: number[] = (program.files || []).map(file => file.id);
        const newFilesIds: number[] = (this.formData.files || []).map(file => (file.id || null));
        for (let i = 0; i < this.formData.files.length; i++) {
          if (!this.formData.files[i].id) {
            promises.push(
              promoProgramApi.addConferenceProgramFile({
                event_id: this.eventId,
                conference_id: program.conference_id,
                program_id: program.id,
                url: this.formData.files[i].url,
                filename: this.formData.files[i].filename,
              })
            );
          }
        }
        for (let i = 0; i < oldFilesIds.length; i++) {
          if (newFilesIds.indexOf(oldFilesIds[i]) < 0) {
            promises.push(
              promoProgramApi.deleteConferenceProgramFile({
                event_id: this.eventId,
                conference_id: program.conference_id,
                program_id: program.id,
                file_id: oldFilesIds[i],
              })
            );
          }
        }
        await Promise.all(promises);
        await this.editSponsorFile(program);
      } catch (error) {
        // TODO: ?
        this.isProcessing = false;
        return;
      }
    }

    this.isProcessing = false;

    // TODO: patch store instead of reloading, but...
    // TODO: there is no API for getting program by id, requesting all of them...
    await this.$store.dispatch('promoProgramStore/reset');
    this.$store.dispatch('promoProgramStore/loadProgram', this.eventId);

    // TODO: success indication?
    this.$router.push(this.backLocation);
  }

  private initFormData(): void {
    if (this.mode === 'create') {
      const dateMoment = this.dateMoment;
      this.formData.date = dateMoment.toDate();
      this.formData.timeStart = dateMoment.clone().hours(9).minute(0).toDate();
      this.formData.timeEnd = dateMoment.clone().hours(10).minute(0).toDate();
      this.formData.title = '';
      this.formData.speakers = [];
      this.formData.isLiveChatOn = true;
      this.formData.isSpeakerChatOn = true;
      this.formData.posterFile = null;
      this.formData.description = '';
      this.formData.videoStreamEmbed = null;
      this.formData.vodFile = null;
      this.formData.files = [];
    } else if (this.mode === 'edit') {
      const program = this.program;

      this.formData.date = new Date(this.transformFromEventDate(program.date_start));
      this.formData.timeStart = new Date(this.transformFromEventDate(program.date_start));
      this.formData.timeEnd = new Date(this.transformFromEventDate(program.date_end));

      this.formData.title = program.title;
      this.formData.speakers = (program.speakers || []).map(speaker => speaker.id);
      this.formData.isLiveChatOn = program.show_live_chat;
      this.formData.isSpeakerChatOn = program.show_speaker_chat;
      this.formData.posterFile = program.poster_url ? {
        url: program.poster_url,
      } : null;
      this.formData.description = program.description;
      this.formData.videoStreamEmbed = program.video_stream_embed || null;
      this.formData.vodFile = program.vod_url ? {
        url: program.vod_url,
      } : null;

      this.formData.files = (program.files || []).map(item => {
        return item;
      });

      this.files = (program.files || []).map(item => {
        return item;
      });

      if (program.sponsors && program.sponsors.length) {
        this.sponsorFile = Object.assign({}, {
          url: program.sponsors[program.sponsors.length - 1].photo_url || '',
          link: program.sponsors[program.sponsors.length - 1].link || '',
          id: program.sponsors[program.sponsors.length - 1].id || null
        });
      }

    }

    // console.log('--------------------------------');
    // console.log('program', this.program);
    // console.log('initFormData', this.$v.formData);
    // console.log('--------------------------------');
  }

  public async postSponsorFile(newConferenceProgram: TConferenceProgram): Promise<void> {
    this.$v.sponsorFile.link.$touch();
    if(this.$v.sponsorFile.$invalid) { return; }

    if (this.sponsorFile.url && newConferenceProgram) {
      return await promoProgramApi.addConferenceProgramSponsor({
        event_id: this.eventId,
        conference_id: this.conferenceId,
        program_id: newConferenceProgram.id,
        photo_url: this.sponsorFile.url,
        link: this.sponsorFile.link,
      });
    }
  }

  public async editSponsorFile(program: TConferenceProgram): Promise<void> {
    this.$v.sponsorFile.link.$touch();
    if(this.$v.sponsorFile.$invalid) { return; }

    if (this.sponsorFile.url) {
      if (program && program.sponsors && program.sponsors.length) {
        await Promise.all(program.sponsors.map(async (item) => {
          await promoProgramApi.deleteConferenceProgramSponsor({
            event_id: this.eventId,
            conference_id: program.conference_id,
            program_id: program.id,
            sponsor_id: item.id
          });
        }));
      }
      return await promoProgramApi.addConferenceProgramSponsor({
        event_id: this.eventId,
        conference_id: program.conference_id,
        program_id: program.id,
        photo_url: this.sponsorFile.url,
        link: this.sponsorFile.link,
      });
    }
  }

  private transform2EventDate(date: Date): string {

    const eventTimezone = momentTimezone.utc(date).tz(this.event.time_region).format('ZZ');

    if (Math.sign(+eventTimezone) >= 1) {
      return this.$moment(date).utc(true).utcOffset(eventTimezone.replace('+', '-')).format(DateTimeFormat.API_DATE_SMALL);
    } else {
      return this.$moment(date).utc(true).utcOffset(eventTimezone.replace('-', '+')).format(DateTimeFormat.API_DATE_SMALL);
    }
  }

  private transformFromEventDate(date: string): string {
    const eventTimezone = momentTimezone.utc(date).tz(this.event.time_region).format('ZZ');
    return this.$moment(date).utc(false).utcOffset(eventTimezone).format(DateTimeFormat.API_DATE_SMALL);
  }

  private formData2CreateConferenceProgramParams(): TCreateConferenceProgramParams {
    const dateStart2eventUtc = this.$moment(this.formData.date)
      .hours(this.formData.timeStart.getHours())
      .minute(this.formData.timeStart.getMinutes()).toDate();
    const dateEnd2eventUtc = this.$moment(this.formData.date)
      .hours(this.formData.timeEnd.getHours())
      .minute(this.formData.timeEnd.getMinutes()).toDate();

    return {
      event_id: this.eventId,
      conference_id: this.conferenceId,
      title: this.formData.title,
      date_start: this.transform2EventDate(dateStart2eventUtc),
      date_end: this.transform2EventDate(dateEnd2eventUtc),
      poster_url: this.formData.posterFile ? this.formData.posterFile.url : '',
      description: this.formData.description,
      video_stream_embed: this.formData.videoStreamEmbed,
      vod_url: this.formData.vodFile ? this.formData.vodFile.url : '',
      show_live_chat: this.formData.isLiveChatOn,
      show_speaker_chat: this.formData.isSpeakerChatOn,
    };
  }

  private formData2PathcConferenceProgramParams(): TPatchConferenceProgramParams {
    const program = this.program;
    const patchParams: TPatchConferenceProgramParams = {
      event_id: this.eventId,
      conference_id: program.conference_id,
      id: program.id,
    };

    if (this.formData.title !== program.title) {
      patchParams.title = this.formData.title;
    }

    const dateStart2eventUtc = this.$moment(this.formData.date)
      .hours(this.formData.timeStart.getHours())
      .minute(this.formData.timeStart.getMinutes()).toDate();
    const dateEnd2eventUtc = this.$moment(this.formData.date)
      .hours(this.formData.timeEnd.getHours())
      .minute(this.formData.timeEnd.getMinutes()).toDate();

    patchParams.date_start = this.transform2EventDate(dateStart2eventUtc);
    patchParams.date_end = this.transform2EventDate(dateEnd2eventUtc);

    const oldPosterUrl = program.poster_url || '';
    const newPosterUrl = this.formData.posterFile ? this.formData.posterFile.url : '';
    if (oldPosterUrl !== newPosterUrl) {
      patchParams.poster_url = newPosterUrl;
    }

    if (this.formData.description !== program.description) {
      patchParams.description = this.formData.description;
    }

    const oldVodUrl = program.vod_url || '';
    const newVodUrl = this.formData.vodFile ? this.formData.vodFile.url : '';
    if (oldVodUrl !== newVodUrl) {
      patchParams.vod_url = newVodUrl;
    }

    if (this.formData.isLiveChatOn !== program.show_live_chat) {
      patchParams.show_live_chat = this.formData.isLiveChatOn;
    }

    if (this.formData.isSpeakerChatOn !== program.show_speaker_chat) {
      patchParams.show_speaker_chat = this.formData.isSpeakerChatOn;
    }

    return patchParams;
  }

  private async updateFoundContacts(): Promise<void> {
    // TODO: not calling sometime?
    this.isSearching = true;
    const searchString = this.searchString.trim();

    const contactsSearchResults: TApiListResponse<TContact> = await this.$store.dispatch('contactsStore/searchContacts', {
      eventId: this.eventId,
      limit: 100,
      offset: 0,
      search: searchString || undefined,
    });

    const foundContacts = (contactsSearchResults && contactsSearchResults.List) || [];
    const foundContactsExcludedSpeakers = foundContacts.filter(item => this.formData.speakers.indexOf(item.id) < 0);
    if (foundContactsExcludedSpeakers.length >= CONTACT_SEARCH_LIMIT) {
      this.foundContacts = foundContactsExcludedSpeakers.slice(0, CONTACT_SEARCH_LIMIT);
    } else {
      this.foundContacts = [...foundContactsExcludedSpeakers];
    }

    this.isSearchedThroughAll = foundContacts.length === ((contactsSearchResults && contactsSearchResults.Total) || -1);
    this.isSearching = false;
  }

  private subscribeToPageEvents(): void {
    fromEvent<MouseEvent>(document, 'click')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(this.onDocumentClick);
  }

  private onDocumentClick(): void {
    this.isSearchResultsVisible = false;
  }

  @Watch('searchString', {immediate: true})
  private onSearchStringChange(): void {
    this.contactsSearch$.next();
  }

  @Watch('files',)
  private onFilesChange(): void {
    this.isFilesLoading = false;
    this.formData.files = this.files;
  }

  @Watch('formData.posterFile.url',)
  private onPosterFileChange(): void {
    if (this.formData.posterFile && this.formData.posterFile.url) {
      this.isPosterFileLoading = false;
    }

  }

  @Watch('formData.vodFile.url', {immediate: true})
  private onAgendaVideoUrlLoadingChange(): void {
    if ((this.formData.vodFile && this.formData.vodFile.url) || this.vodError) {
      this.isVodLoading = false;
    }
  }

  @Watch('sponsorFile.link', {immediate: true})
  private onSponsorLinkChange(): void {
    if (this.sponsorFile.link) {
      this.sponsorLink = this.sponsorFile.link;
    }
  }

  @Watch('sponsorFile.url', {immediate: true})
  private onSponsorFileUrlLoadingChange(): void {
    const program = this.program;
    if ((this.sponsorFile && this.sponsorFile.url) || this.sponsorFileError) {
      this.isSponsorFileLoading = false;
    }

    if (this.sponsorLink) {
      this.sponsorFile.link = this.sponsorLink;
    } else if (program && program.sponsors && program.sponsors[program.sponsors.length - 1].link) {
      this.sponsorFile.link = program.sponsors[program.sponsors.length - 1].link;
    }

  }

  @Watch('programId', {immediate: true})
  private onProgramIdChange(): void {
    this.getProgramQuestionnaires();
  }

  private openAddUserPopup(): void {
    this.isAddUserPopupVisible = true;
  }

  private closeAddUserPopup(): void {
    this.isAddUserPopupVisible = false;
  }

  private deletePosterFile(): void {
    this.formData.posterFile = null;
  }

  private async deleteSponsorFile(): Promise<void> {
    const program = this.program;
    if (program && program.sponsors) {

      await Promise.all(program.sponsors.map(async (item) => {
        await promoProgramApi.deleteConferenceProgramSponsor({
          event_id: this.eventId,
          conference_id: program.conference_id,
          program_id: program.id,
          sponsor_id: item.id
        });
      }));
    }
    this.sponsorFile = {
      link: ''
    };

  }

  private rewind(targetRefName: string): void {
    const PERCENTAGE = 0.25;

    if (this.$refs[targetRefName] && PERCENTAGE) {
      try {
        const vid = (this.$refs[targetRefName] as HTMLVideoElement);
        vid.currentTime = vid.duration * PERCENTAGE;
      } catch (e) {
      }
    }

  }

  public setVodError(err: string): void {
    this.vodError = err || '';

    setTimeout(() => {
      this.vodError = '';
      this.isVodLoading = false;
    }, 3000);
  }

  public setFilesError(err: string): void {
    this.filesError = err || '';
    setTimeout(() => {
      this.filesError = '';
      this.isFilesLoading = false;
    }, 3000);
  }

  public setPosterFileError(err: string): void {
    this.posterFileError = err || '';
    setTimeout(() => {
      this.posterFileError = '';
      this.isPosterFileLoading = false;
    }, 3000);
  }

  public setSponsorFileError(err: string): void {
    this.sponsorFileError = err || '';
    setTimeout(() => {
      this.sponsorFileError = '';
      this.isSponsorFileLoading = false;
    }, 3000);
  }

  public filesLoading(value: boolean): void {
    this.isFilesLoading = value;
  }

  public vodLoading(value: boolean): void {
    this.isVodLoading = value;
  }

  public posterFileLoading(value: boolean): void {
    this.isPosterFileLoading = value;
  }

  public sponsorFileLoading(value: boolean): void {
    this.isSponsorFileLoading = value;
  }

  private deleteVideoFileUrl(): void {
    if (!this.formData.vodFile) {
      return;
    }
    this.formData.vodFile = null;
  }

  private deleteFile(index: number): void {
    if (!this.formData.files[index]) {
      return;
    }
    const removedUrl = this.formData.files[index].url;
    this.formData.files.splice(index, 1);
    this.files.forEach((item, index) => {
      if (item.url === removedUrl) {
        this.files.splice(index, 1);
      }
    });
  }

  private toggleQuestionnairePopup(): void {
    this.isQuestionnairePopupVisible = !this.isQuestionnairePopupVisible;
    if (!this.isQuestionnairePopupVisible) {
      this.editingPoll = null;
    }
  }

  private onQuestionnairePopupSuccess(updatedPoll: TQuestionnaire): void {
    const foundIndex = this.questionnaires.findIndex((poll) => poll.id === updatedPoll.id);
    if (foundIndex !== -1) {
      this.questionnaires[foundIndex] = updatedPoll;
    } else {
      this.questionnaires.push(updatedPoll);
    }
    this.toggleQuestionnairePopup();
  }

  private async getProgramQuestionnaires(): Promise<void> {
    if (!this.programId) {
      return;
    }

    try {
      const result: TQuestionnaire[] = await questionnairesApi.getProgramQuestionnaires({
        eventId: this.eventId,
        programId: this.programId
      });
      if (result) {
        this.questionnaires = result;
      }
    } catch {
      this.questionnaires = [];
    }
  }

  private editQuestionnaire(poll: TQuestionnaire): void {
    this.editingPoll = poll;
    this.toggleQuestionnairePopup();
  }

  private requestDeleteQuestionnaire(poll: TQuestionnaire): void {
    this.pollToDeleteId = poll.id;
  }

  private async deletePoll(): Promise<void> {
    if (this.pollToDeleteId >= 0) {
      await questionnairesApi.deleteQuestionnaire({
        eventId: this.eventId,
        questionnaireId: this.pollToDeleteId
      });
    }
    const deletedPollIndex = this.questionnaires.findIndex((poll): boolean => poll.id === this.pollToDeleteId);
    this.questionnaires.splice(deletedPollIndex, 1);
    this.pollToDeleteId = null;
  }

}
